import React from 'react';
import styled from 'styled-components';

const HeroStyle = styled.div`
    text-align: center;
    background: url(${props => props.bg}) no-repeat center;
    background-size: cover;
    font-size: 3rem;
    color: #ffce7e;
    height: 700px;
    @media (max-width: 768px) {
        font-size: 2rem;
        height: auto;
        background-position: 20%;
    }
    div {
      padding: 100px 15% 50px;
      height: 700px;
      background-color: rgba(47, 47, 47, 0.41);
      line-height: 1.5;
      @media (max-width: 768px) {
        height: auto;
        padding: 50px;
      }
    }
`;

export default function HeroTwoHeadings({titleOne, titleTwo, bg, subtitleOne, subtitleTwo}) {
    return (
        <HeroStyle bg={bg}>
            <div>
                <h2>{titleOne}</h2>
                <p>{subtitleOne}</p>
                <h2>{titleTwo}</h2>
                <p>{subtitleTwo}</p>
            </div>
        </HeroStyle>
    )
}