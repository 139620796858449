import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import PortableText from "@sanity/block-content-to-react";
import Layout from "../components/layout";
import GetInvolved from "../components/getinvolved";
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import Seo from "../components/seo";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
// import bg from '../assets/images/about-bg.png';
import mBg from '../assets/images/logo-bg.png';
import { Modal } from "../components/modal";
import HeroTwoHeadings from "../components/heroTwoHeadings";
import urlFor from "../helper/urlFor";

const MVStyle = styled.div`
    text-align: center;
    @media (max-width: 425px) {
      background-position: 30% 50%;
    }
    h2 {
      font-size: 3rem;
      color: #ffce7e;
    }

    p {
      font-size: 1.375rem;
      padding-bottom: 3rem;
      color: #fff;
    }

    div {
      background-color: rgba(47, 47, 47, 0.41);
      @media (max-width: 425px) {
      }
    }
`;

const HowStarted = styled.div`
  padding: 150px;
  @media (max-width: 425px) {
    padding: 100px 30px;
  }
  @media (min-width: 768px and max-width: 1024px) {
    padding: 100px;
  }
  h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #14213D;
  }
  p {
    font-size: 1.2rem;
    padding: 1rem 0;
  }
`;

const MileStones = styled.div`
  background-color: #7dcbef;
  padding: 150px;
  text-align: center;
  @media (max-width: 425px) {
    padding: 100px 30px;
  }
  @media (min-width: 768px and max-width: 1024px) {
    padding: 100px;
  }
    h2 {
      font-size: 2.5rem;
      color: #14213D;
    }
    p {
      text-align: left;
      font-size: 1.2rem;
      padding: 1rem 0;
    }
`;

const MileBg = styled.div`
  background-image: url(${mBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const PresidentStyle = styled.div` 
  background-color: #FFE7BF;
  padding: 150px;
  text-align: center;
  @media (max-width: 425px) {
    padding: 100px 30px;
    text-align: left;
  }
  @media (min-width: 768px and max-width: 1024px) {
    padding: 100px;
  }
  h2 {
      font-size: 2.5rem;
      color: #14213D;
      text-align: center;
    }
    p {
      text-align: left;
      font-size: 1.2rem;
      padding: 1rem 0;
    }
  .sig {
    text-align: left;
  }
`;


const TeamWrapper = styled.div`
  text-align: center;
  padding: 100px 100px 80px;
  @media (max-width: 425px) {
    padding: 100px 30px 80px;
  }
  @media (min-width: 768px and max-width: 1024px) {
    padding: 100px;
  }
  h2 {
    font-size: 2.5rem;
    padding-bottom: 2rem;
    color: #14213D;
  }
`;

const TeamStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  gap: 30px;
  @media (max-width: 425px) {
    flex-wrap: no-wrap;
  }
`;

const MemberStyle = styled.div`
  flex: 0 1 30%;
  margin-bottom: 20px;

  @media (max-width: 425px) {
    flex: 1 100%;
  }
  
  @media (min-width: 426px and max-width: 1029px) {
    flex: 0 1 45%;
  }
  
  h4 {
    color: #14213D;
    font-size: 1.3rem;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }
  
  h6 {
    color: #219EBC;
    font-weight: 400;
    font-size: 1rem;
  }
`;

const ModalButton = styled.button`
  border: none;
`;

const BoardofT = styled.div`
width: 70%;

@media (min-width: 768px) {
  width: 100%;
}

margin: auto;
text-align: center; 
padding-top: 100px 0;
> div {
  padding-top: 50px;
    h2 {
      padding-bottom: 3rem;
    }
}
  ul {
    list-style-type: none;
    columns: 100px 2;
    font-size: 1.4rem;
    li {
      padding-bottom: .8rem;
    }
  }
`

const SEC = styled.div`
  text-align: center;

`

const sanityConfig = {projectId: '7nqypbju', dataset: 'production'}

const serializer = {
  types: {
    image: (props) => (
      <Img fluid={getFluidGatsbyImage(props.node.asset._ref, {maxWidth: 500}, sanityConfig)} />
    ),
  },
}

const AboutPage = ({data}) => {
  const { t, i18n } = useTranslation("about");
  const [showModal, setShowModal] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [button, setButton] = useState('');
  
  const openModal = (e, member = '') => {
    document.body.style.overflow = "hidden";
    setButton(e.target.innerText);
    setMemberData(member);
    setShowModal(prev => !prev);
  }

  const missionVision = data.about._rawContent.filter(content => content._type === 'twoHeadings');
  const sectionText = data.about._rawContent.filter(content => content._type === 'sectionText');
  const hero = data.about._rawContent.filter(content => content._type === 'twoHeadings');
  return (
    <Layout>
      <Seo title="About" />
        <MVStyle>
        <HeroTwoHeadings 
          titleOne={i18n.language === "en" ? missionVision[0].headingOne.en : missionVision[0].headingOne.ph} 
          subtitleOne={i18n.language === "en" ? missionVision[0].subheadingOne.en : missionVision[0].subheadingOne.ph}
          titleTwo={i18n.language === "en" ? missionVision[0].headingTwo.en : missionVision[0].headingTwo.ph} 
          subtitleTwo={i18n.language === "en" ? missionVision[0].subheadingTwo.en : missionVision[0].subheadingTwo.ph}
          bg={urlFor(hero[0].bgImage.asset)}/>
        </MVStyle>
        <HowStarted>
          <h2>{i18n.language === "en" ? sectionText[0].title : sectionText[1].title}</h2>
          {
            i18n.language === "en" ?
            <PortableText
              blocks={sectionText[0].body}
            /> :
             <PortableText
              blocks={sectionText[1].body}
            />
          }
        </HowStarted>
        <MileStones>
          <MileBg>
            <h2>{i18n.language === "en" ? sectionText[2].title : sectionText[3].title}</h2>
            {
              i18n.language === "en" ?
              <PortableText blocks={sectionText[2].body}/> :
              <PortableText blocks={sectionText[3].body}/>

            }
          </MileBg>
        </MileStones>
        <PresidentStyle>
        <h2>{i18n.language === "en" ? sectionText[4].title : sectionText[5].title}</h2>
            {
              i18n.language === "en" ?
              <PortableText blocks={sectionText[4].body}/> :
              <PortableText blocks={sectionText[5].body}/>

            }
          <div className="sig">
            <Img fixed={data.file.childImageSharp.fixed} />
          </div>
        </PresidentStyle>
        <TeamWrapper>
          <h2>Our Team</h2>
          <TeamStyles>
          {data.allSanityTeam.nodes.map(member => 
            <MemberStyle key={member.id}>
             { member.image && <Img fluid={member.image.asset.fluid} alt={member.name} /> }
              <h4>{member.name}</h4>
              <h6>{member.role}</h6>
              {
                i18n.language === "en" ?
                <ModalButton className="button" onClick={(e) => { openModal(e, member) }}>Read Bio</ModalButton> :
                <ModalButton className="button" onClick={(e) => { openModal(e, member) }}>Basahin ang Bio</ModalButton>
              }
            </MemberStyle>
          )}
          </TeamStyles>
        </TeamWrapper>
        <Modal showModal={showModal} setShowModal={setShowModal}>
            { 
              showModal && (
                button === 'CLICK HERE' ?
                (
                  <div>
                    <h1>{sectionText[7].title}</h1>
                    <PortableText blocks={sectionText[7].body} serializers={serializer} />
                  </div>
                ) :
                (
                  <div>
                    <h1>{memberData.name}</h1>
                    <h2>{memberData.role}</h2>
                    { i18n.language === 'en' 
                    ? memberData.bio.en.split('\n').map((line, index) => (
                    <p key={index}>{line}</p> ))  
                    : memberData.bio.ph.split('\n').map((line, index) => (
                    <p key={index}>{line}</p> ))}
                  </div>
                )
               )
            }
        </Modal>
        <BoardofT>
          <HowStarted>
            <h2>{sectionText[6].title}</h2>
              <PortableText blocks={sectionText[6].body} />
          </HowStarted>
        </BoardofT>
        <SEC>
            <HowStarted>
              <h2>{sectionText[7].title}</h2>
              <p>To view our SEC Certification</p>
              <button className="button" onClick={(e) => { openModal(e) } }>Click Here</button>
            </HowStarted>
        </SEC>
        <GetInvolved />
    </Layout>
  )
}

export const query = graphql`
 query {
  about: sanityPages(name: {eq: "About"}) {
    _rawContent
  }
  allSanityTeam(sort: {fields: _createdAt}) {
    nodes {
      name
      id
      role
      slug {
        current
      }
      image {
        asset {
          fluid (maxWidth: 320, maxHeight: 460) {
            ...GatsbySanityImageFluid
        }
        }
      }
      bio {
        en
        ph
      }
    }
  }
  file(name: {eq: "pres-sig"}) {
    id
    childImageSharp {
      fixed (width: 250) {
				...GatsbyImageSharpFixed
      }
    }
  }
}
`

export default AboutPage
